import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "cp-admin-title-tab__wrapper" }
const _hoisted_2 = { class: "cp-badges-container" }
const _hoisted_3 = {
  key: 0,
  class: "cp-badge cp-badge_test"
}
const _hoisted_4 = { class: "cp-flex cp-flex--align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tabs_nav = _resolveComponent("tabs-nav")!
  const _component_app_badge_cards = _resolveComponent("app-badge-cards")!
  const _component_star_icon = _resolveComponent("star-icon")!
  const _component_title_return = _resolveComponent("title-return")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tabs_nav, {
      items: _ctx.tabsNav,
      "active-id": _ctx.currentTab,
      params: { employeeId: _ctx.employeeId }
    }, null, 8, ["items", "active-id", "params"]),
    _createVNode(_component_title_return, {
      icon: _ctx.titlePage && 'user',
      "icon-size": "19",
      onClick: _ctx.returnBefore
    }, {
      "icon-badge": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_app_badge_cards, { data: _ctx.cards }, null, 8, ["data"]),
          (_ctx.isTest)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Test"))
            : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createTextVNode(_toDisplayString(_ctx.titlePage) + " ", 1),
          (_ctx.titlePage && _ctx.rating)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_star_icon, {
                  class: "cp-margin__l--7 cp-padding__r--8",
                  isFilledInside: true,
                  size: 14,
                  color: "#515184"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.rating), 1)
              ], 64))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["icon", "onClick"])
  ]))
}
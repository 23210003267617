
import moment from 'moment';
import { Component, Vue } from '@/lib/decorator';
import TitleTab from '../_titleTab.vue';

import EmployeeEntityModule from '@/store/employees/entity';
import EmployeeClientRelationsModule from '@/store/employees/clientRelations ';
import { PageInterface } from '@/lib/layouts/page/page.interface';

import { GuiButton, GuiLoader } from '@library/gigant_ui';
import IconFont from '@/components/icons/IconFont.vue';
import AppTable from '@/components/ui/table/Table.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import TextDatetime from '@/components/table-items/TextDatetime.vue';

@Component({
  components: {
    TitleTab,
    AppCol,
    AppRow,
    AppTable,
    IconFont,
    GuiButton,
    GuiLoader,
    TextDatetime,
  },
  beforeMount() {
    this.employeeId = this.$route.params.employeeId;
  },
})
export default class EmployeesEntityIndex extends Vue {
  employeeId = '';

  get settings(): PageInterface {
    return EmployeeClientRelationsModule.pageSettings;
  }

  get currentTab(): string {
    return EmployeeEntityModule.tabsNav.relations?.id;
  }

  get isLoading(): boolean {
    return EmployeeClientRelationsModule.isLoading;
  }

  getProhibitionEndDate(relationEndDate: Date): string {
    return moment(relationEndDate).add(2, 'years').add(1, 'day').format();
  }

  sort(header: { id: string; sort: { value: string } }): void {
    EmployeeClientRelationsModule.sort({ field: header.id, sort: header.sort.value || '' });
  }

  removeRelation(id: string): void {
    EmployeeClientRelationsModule.removeRelation(id);
  }

  selectAmount(value: string): void {
    EmployeeClientRelationsModule.updatePageAmountItems(value);
  }

  async mounted(): Promise<void> {
    await EmployeeClientRelationsModule.init(this.employeeId as string);
  }
}

import { EmployeeRelationEntityModel } from '@/interfaces/models/employees.interface';
import InputSearchDropEntity from '@/lib/formFactory/inputSearchDropEntity';

export default class RelationEntityModel {
  model: EmployeeRelationEntityModel;

  constructor() {
    const shopEntity = new InputSearchDropEntity('shop');
    shopEntity.setTitle('');
    shopEntity.setRequired(true);
    shopEntity.setAction('employeeRelationEntity/updateEmployee');

    this.model = {
      shop: shopEntity.entity[shopEntity.entityName],
      isFavourite: {},
      comment: '',
    };
  }
}

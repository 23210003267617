
import { Component, Vue } from '@/lib/decorator';

import EmployeesModule from '@/store/employees';
import EmployeeEntityModule from '@/store/employees/entity';
import EmployeeShopRelationsModule from '@/store/employees/shopRelations';
import IconFont from '@/components/icons/IconFont.vue';

import { GuiButton, GuiLoader } from '@library/gigant_ui';
import ModalBlock from '@/components/Modal.vue';
import FormEditBlock from '@/views/employees/relations/_edit.vue';
import AppTable from '@/components/ui/table/Table.vue';
import FavoriteItem from '@/components/table-items/FavoriteItem.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import userModule from '@/store/user';

type Tab = 'shops' | 'clients';

@Component({
  components: {
    AppCol,
    AppRow,
    FavoriteItem,
    AppTable,
    FormEditBlock,
    IconFont,
    ModalBlock,
    GuiLoader,
    GuiButton,
  },
  beforeMount() {
    this.employeeId = this.$route.params.employeeId;
  },
})
export default class EmployeesEntityIndex extends Vue {
  employeeId = '';
  activeTab: Tab = 'shops';

  get settings(): PageInterface {
    return EmployeeShopRelationsModule.pageSettings;
  }

  get currentTab(): string {
    return EmployeeEntityModule.tabsNav.relations?.id;
  }

  get modalParams(): Record<string, Record<string, string | boolean | Record<string, string> | null>> {
    return EmployeeShopRelationsModule.modalParams;
  }

  get usePermission(): boolean {
    return userModule.userHasPermission('CAN_READ_CLIENT_MARKET');
  }

  getColorButtonTab(tab: Tab): string {
    return tab === this.activeTab ? 'accent' : 'primary';
  }

  closeModal(): void {
    EmployeeShopRelationsModule.hideModal('edit');
  }

  sort(header: { id: string; sort: { value: string } }): void {
    EmployeeShopRelationsModule.sort({ field: header.id, sort: header.sort.value || '' });
  }

  deleteRow(id: number): void {
    EmployeeShopRelationsModule.deleteFromFavorite({
      shopId: id.toString(),
      employeeId: this.employeeId as string,
    });
  }

  openStatus(item: {
    id: number;
    marketAccessible: boolean;
    comment: string;
    clientMarketId: number;
    clientMarket: string;
    _actions: string[];
  }): void {
    if (!item.marketAccessible) {
      return;
    }

    EmployeeShopRelationsModule.updateStatus({
      id: item.id.toString(),
      comment: item.comment,
      employeeId: this.employeeId as string,
      shop: {
        id: item.clientMarketId.toString(),
        name: item.clientMarket,
        actions: item._actions,
      },
    });
  }

  selectAmount(value: string): void {
    EmployeeShopRelationsModule.updatePageAmountItems(value);
  }

  mounted(): void {
    EmployeeShopRelationsModule.init(this.employeeId as string);
    if (EmployeesModule.pageSettings.titleEdit === '') {
      EmployeeEntityModule.initEdit(this.employeeId as string);
    }
  }
}

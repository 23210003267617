
import { Component, Watch } from '@/lib/decorator';
import { ref } from 'vue';

import Form from '@/layouts/Form';
import EditableItem from '@/components/form/EditableItem.vue';
import ModalBtnBottom from '@/layouts/ModalBtnBottom.vue';
import FormTextarea from '@/components/form/Textarea/index.vue';
import FormButton from '@/components/form/Button/index.vue';
import formValidate from '@/lib/formFactory/validate';

import EmployeeRelationEntityModule from '@/store/employees/relationEntity';
import EmployeeRelationModule from '@/store/employees/shopRelations';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { strings } from '@/lib/stringConst';
import EmployeeShopRelationsEntityModule from '@/store/employees/relationEntity';
import { EmployeeRelationEntityModel } from '@/interfaces/models/employees.interface';
import { GuiFormRadio } from '@library/gigant_ui';

@Component({
  components: {
    GuiFormRadio,
    EditableItem,
    ModalBtnBottom,
    FormTextarea,
    FormButton,
  },
})
export default class EmployeeRelationFormEdit extends Form {
  comment = this.model.comment ?? '';
  error = '';
  form = ref(null);
  validate = formValidate(this.form);

  isFavouriteItems = [
    { value: 'is_favourite', name: 'Любимый', id: 'is_favourite', icon: 'smile-love' },
    { value: 'is_liked', name: 'Нравится', id: 'is_liked', icon: 'smile-like' },
    { value: 'is_hated', name: 'Нелюбимый', id: 'is_hated', icon: 'smile-hated' },
  ];
  isFavourite = this.model.isFavourite ?? { id: null, value: '' };

  get model(): EmployeeRelationEntityModel {
    return EmployeeRelationEntityModule.model;
  }

  get text(): Record<string, string> {
    return strings;
  }

  updateFavorite(value: { id: null | string; value: string }): void {
    this.isFavourite = { id: value.id, value: value.value };
    this.error = '';
  }

  updateComment(value: string): void {
    EmployeeRelationEntityModule.updateCommentValue(value);
  }

  async save(): Promise<void> {
    if (this.isFavourite.value === 'is_hated' && (!this.comment || !this.comment.length)) {
      this.error = 'Для статуса "Нелюбимый" необходимо оставить комментарий';

      ResponseHandlerModule.showNotify({ message: this.error, type: 'fail' });

      return;
    }

    EmployeeShopRelationsEntityModule.updateIsFavourite(this.isFavourite);
    EmployeeShopRelationsEntityModule.updateCommentValue(this.comment);

    EmployeeRelationModule.saveUpdate();
  }

  @Watch('model.comment')
  onChangeModelComment(value: string): void {
    this.comment = value;
  }

  @Watch('model.isFavourite')
  onChangeModelIsFavourite(value: { id: string; value: string }): void {
    this.isFavourite = value;
  }
}

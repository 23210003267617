import EntityBaseModule from '@/store/entity';
import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';

import { EmployeeRelationEntityModel } from '@/interfaces/models/employees.interface';
import EntityModel from '@/store/employees/relationEntityModel';
import EmployeeRelationModule from '@/store/employees/shopRelations';

export const MODULE_NAME = 'employeeRelationsEntity';
import store from '@/store';
import { getFavoriteEmployeeById, saveFavoriteEmployee } from '@/api/shop';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { InputSearchDrop } from '@/lib/formFactory/inputSearchDrop.interface';
import { RadioForm } from '@/lib/formFactory/radioForm.interface';
import { strings } from '@/lib/stringConst';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class EmployeeShopRelationsEntityModule extends EntityBaseModule {
  model: EmployeeRelationEntityModel;

  constructor(module: EmployeeShopRelationsEntityModule) {
    super(module);

    const entityModel = new EntityModel();
    this.model = entityModel.model;
  }

  @Mutation
  UPDATE_SHOP_VALUE(value: { id?: null | string; value?: string }) {
    this.model.shop = value as InputSearchDrop;
  }

  @Mutation
  UPDATE_COMMENT_VALUE(value: string) {
    this.model.comment = value;
  }

  @Mutation
  SET_IS_FAVORITE(value: { id: string | null; value: string }) {
    this.model.isFavourite = value;
  }

  @Action({ rawError: true })
  editFavorite(params: { id: string; comment: string; employeeId: string; shop: { id: string; name: string } }) {
    this.getById(params);
    EmployeeRelationModule.showModal('edit');
  }

  @Action({ rawError: true })
  async getById(params: { id: string; comment: string; employeeId: string; shop: { id: string; name: string } }) {
    try {
      const result = await getFavoriteEmployeeById(params.shop.id, params.employeeId);
      result['shop'] = params.shop;
      result['comment'] = params.comment ?? '';
      this.setInfoById(result);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.message || error.response.data.errors.fields, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  setInfoById(info: { status: string; comment: string; shop: { id: string; name: string } }) {
    this.updateIsFavourite({ id: info.status, value: info.status });
    this.updateCommentValue(info.comment);

    this.context.commit('UPDATE_SHOP_VALUE', info.shop);
  }

  @Action({ rawError: true })
  updateIsFavourite(value: RadioForm) {
    this.context.commit('SET_IS_FAVORITE', value);
  }

  @Action({ rawError: true })
  updateCommentValue(value: string) {
    this.context.commit('UPDATE_COMMENT_VALUE', value);
  }

  @Action({ rawError: true })
  updateShopValue(value: string) {
    this.context.commit('UPDATE_SHOP_VALUE', value);
  }

  @Action({ rawError: true })
  async saveUpdate(params: { shopId: string; employeeId: string }) {
    const { shopId, employeeId } = params;

    try {
      const data = await this.prepareFormData();
      const result = await saveFavoriteEmployee(shopId, employeeId, data);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
      await EmployeeRelationModule.initListById(employeeId);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async prepareFormData() {
    try {
      const data: { status: string; comment: string } = {
        status: this.model.isFavourite.value as string,
        comment: this.model.comment,
      };

      return data;
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });

      return {};
    }
  }

  @Action({ rawError: true })
  resetModel() {
    this.context.commit('UPDATE_SHOP_VALUE', {});
    this.context.commit('SET_IS_FAVORITE', { id: null, value: '' });
    this.context.commit('UPDATE_COMMENT_VALUE', '');

    this.context.commit('RESET_ERROR_BY_FIELD', 'shop');
  }
}

export default getModule(EmployeeShopRelationsEntityModule);

/*
 *
 * */

import { InputSearchDrop } from '@/lib/formFactory/inputSearchDrop.interface';
export default class InputSearchDropEntity {
  defaultValue: InputSearchDrop;
  entityName: string;
  entity: any;

  constructor(name: string) {
    this.defaultValue = {
      key: name,
      type: 'input-search-drop',
      title: '',
      placeholder: '',
      name: name,
      value: '',
      actionSearch: '',
      action: '',
      list: {},
      hintsList: {},
      size: 250,
      required: false,
      error: {
        class: '',
        message: '',
      },
      validation: {
        empty: '',
        regex: '',
      },
    };
    this.entityName = name;
    this.entity = { [this.entityName]: this.defaultValue };
  }

  setType(type: string) {
    this.entity[this.entityName]['type'] = type;
  }

  setTitle(title: string) {
    this.entity[this.entityName]['title'] = title;
  }

  setPlaceholder(placeholder: string) {
    this.entity[this.entityName]['placeholder'] = placeholder;
  }

  setAction(action: string) {
    this.entity[this.entityName]['action'] = action;
  }

  setSearchAction(action: string) {
    this.entity[this.entityName]['actionSearch'] = action;
  }

  setRequired(required: boolean) {
    this.entity[this.entityName]['required'] = required;
  }

  setMask(mask: {
    regex?: string;
    mask?: string;
    alias?: string;
    inputFormat?: string;
    clearIncomplete?: boolean;
    placeholder?: string;
    minChars?: number;
  }) {
    this.entity[this.entityName]['mask'] = mask;
  }

  setValidation(validation: any) {
    this.entity[this.entityName]['validation'] = validation;
  }
}


import { Component, Vue } from '@/lib/decorator';
import TitleTab from '../_titleTab.vue';

import EmployeeEntityModule from '@/store/employees/entity';

import { GuiButton } from '@library/gigant_ui';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import ShopsTab from './_shops.vue';
import ClientsTab from './_clients.vue';
import UserModule from '@/store/user';

type Tab = 'shops' | 'clients';

@Component({
  components: {
    TitleTab,
    AppCol,
    AppRow,
    GuiButton,
    ShopsTab,
    ClientsTab,
  },
})
export default class EmployeesEntityIndex extends Vue {
  activeTab: Tab = 'shops';

  get currentTab(): string {
    return EmployeeEntityModule.tabsNav.relations?.id;
  }

  get canReadLaborRelations(): boolean {
    return UserModule.userHasPermission('CAN_READ_LABOR_RELATIONS');
  }

  getColorButtonTab(tab: Tab): string {
    return tab === this.activeTab ? 'accent' : 'primary';
  }
}

export const modalParams = {
  edit: {
    isShow: false,
    title: 'Редактировать сотрудника',
  },
  delete: {
    id: null,
    employeeId: null,
    name: '',
    text: 'Ты действительно хочешь удалить ${name} из любимого/нелюбимого?',
    handlers: {
      yes: 'employeeRelations/deleteById',
      no: '',
    },
  },
  hated: {
    text: 'Все будущие смены этого сотрудника в объекте удалятся. Вы точно хотите этого?',
    buttonsText: {
      yes: 'Да',
      no: 'Нет',
    },
    handlers: {
      yes: 'employeeRelations/saveUpdate',
      no: '',
    },
  },
};

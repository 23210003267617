import API from '@/config/api-routers';
import { DeleteResponse, FormData, FormResponse } from '@/interfaces/shared';
import { AssemblerUrl } from '@/lib/UtilsApi';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { makeDeleteRequest, makeFormRequest, makeGetRequest } from './requests';

export const getLaborRelationsList = async (
  pageCurrent = 1,
  pageAmountItems = '',
  filter = ''
): Promise<TableApiInterface> => {
  const assemblerUrl = new AssemblerUrl(API.laborRelations.list);
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addFilter(filter).getUrl();
  const result = await makeGetRequest<{ result: { table: TableApiInterface } }>(url);

  return result.data.result.table;
};

export const createLaborRelation = async (data: FormData): Promise<FormResponse> => {
  const result = await makeFormRequest(API.laborRelations.create, data);

  return result.data.result;
};

export const deleteLaborRelation = async (relationId: string): Promise<DeleteResponse> => {
  const result = await makeDeleteRequest(API.laborRelations.delete(relationId));

  return result.data.result;
};

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_text_datetime = _resolveComponent("text-datetime")!
  const _component_icon_font = _resolveComponent("icon-font")!
  const _component_app_table = _resolveComponent("app-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (Object.keys(_ctx.settings.table).length === 0)
      ? (_openBlock(), _createBlock(_component_app_col, {
          key: 0,
          grow: 1
        }, {
          default: _withCtx(() => [
            _createVNode(_component_gui_loader, { absoluteCenter: "" })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (Object.values(_ctx.settings.table).length > 0)
      ? (_openBlock(), _createBlock(_component_app_table, {
          key: 1,
          class: "cp-margin__t--small grow-1",
          headers: _ctx.settings.table.titles,
          items: _ctx.settings.table.rows,
          page: _ctx.settings.table.pagination.currentPage,
          "total-pages": _ctx.settings.table.pagination.totalPages,
          "total-items": _ctx.settings.table.totalItems,
          "page-items": _ctx.settings.pageAmountItems && +_ctx.settings.pageAmountItems,
          "is-loading": _ctx.isLoading,
          onChangePage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.dispatch(_ctx.settings.actionPagination, $event))),
          onSelectAmount: _ctx.selectAmount,
          onSort: _ctx.sort
        }, {
          "item-clientName": _withCtx(({ value, item }) => [
            _createVNode(_component_router_link, {
              class: "app-table-link",
              to: { name: 'customers_shops', params: { customerId: item.clientId } },
              target: "_blank"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(value), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          "item-startDate": _withCtx(({ value }) => [
            value
              ? (_openBlock(), _createBlock(_component_text_datetime, {
                  key: 0,
                  class: "cp-text-overflow",
                  value: value,
                  "format-to": "DD.MM.YYYY"
                }, null, 8, ["value"]))
              : _createCommentVNode("", true)
          ]),
          "item-endDate": _withCtx(({ value }) => [
            value
              ? (_openBlock(), _createBlock(_component_text_datetime, {
                  key: 0,
                  class: "cp-text-overflow",
                  value: value,
                  "format-to": "DD.MM.YYYY"
                }, null, 8, ["value"]))
              : _createCommentVNode("", true)
          ]),
          "item-prohibitionEndDate": _withCtx(({ item }) => [
            (item.endDate)
              ? (_openBlock(), _createBlock(_component_text_datetime, {
                  key: 0,
                  class: "cp-text-overflow",
                  value: _ctx.getProhibitionEndDate(item.endDate),
                  "format-to": "DD.MM.YYYY"
                }, null, 8, ["value"]))
              : _createCommentVNode("", true)
          ]),
          [`item-_actions`]: _withCtx(({ header, item }) => [
            (item[header.id].includes('delete'))
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: "mr-admin-actions-icons",
                  onClick: ($event: any) => (_ctx.removeRelation(item.id))
                }, [
                  _createVNode(_component_icon_font, {
                    class: "app-table-icon app-table-icon--active",
                    icon: "close",
                    size: "11"
                  })
                ], 8, _hoisted_1))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["headers", "items", "page", "total-pages", "total-items", "page-items", "is-loading", "onSelectAmount", "onSort"]))
      : _createCommentVNode("", true)
  ], 64))
}
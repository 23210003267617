import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';

import UserModule from '@/store/user';
import ResponseHandlerModule from '@/store/modules/responseHandler';

import { PageSort } from '@/lib/layouts/page/page.interface';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { UITable } from '@/lib/util/tableUtils';
import { convertObjectToQueryParams } from '@/lib/Utils';
import { strings } from '@/lib/stringConst';
import { deleteLaborRelation, getLaborRelationsList } from '@/api/laborRelations';

const MODULE_NAME = 'employeeClientRelations';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
export class EmployeeClientRelationsModule extends PageBaseModule {
  isLoading = false;
  employeeId = '';

  constructor(module: EmployeeClientRelationsModule) {
    super(module);

    const page = new PageEntity();
    this.pageSettings = page.values;
  }

  @Mutation
  SET_SORT(sorts: PageSort): void {
    this.pageSettings.sort = sorts;
    window.localStorage.employeesRelationsSort = JSON.stringify(sorts);
  }

  @Mutation
  SET_EMPLOYEE_ID(id: string): void {
    this.employeeId = id;
  }

  @Mutation
  UPDATE_IS_LOADING(value: boolean): void {
    this.isLoading = value;
  }

  @Action({ rawError: true })
  async getList(): Promise<void> {
    try {
      this.context.commit('UPDATE_IS_LOADING', true);

      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const filter =
        '&' +
        convertObjectToQueryParams({
          filters: [
            {
              id: 'employeeId',
              value: this.employeeId,
            },
          ],
        });

      const result = await getLaborRelationsList(this.pageSettings.pageCurrent, itemsQuery, filter);

      await this.context.dispatch('setList', result);
    } catch (error) {
      this.context.commit('setGlobalError', false);
    } finally {
      this.context.commit('UPDATE_IS_LOADING', false);
    }
  }

  @Action({ rawError: true })
  setList(table: TableApiInterface): void {
    const uiTable = new UITable(table);
    const sorts = Object.values(this.pageSettings.sort);
    const canEdit = UserModule.userHasPermission('CAN_EDIT_LABOR_RELATIONS');

    uiTable
      .addColumnByIndex(4, { id: 'prohibitionEndDate', name: 'Дата разрешения на работу', visible: true })
      .setProperty('clientName', 'name', 'Клиент')
      .removeColumn('employeeFullName')
      .setSortableValues(sorts);

    if (!canEdit) {
      uiTable.removeColumn('_actions');
    }

    this.context.commit('SET_TABLE', uiTable.getTable());
  }

  @Action({ rawError: true })
  async removeRelation(id: string): Promise<void> {
    try {
      this.context.commit('UPDATE_IS_LOADING', true);
      await deleteLaborRelation(id);

      ResponseHandlerModule.showNotify({
        message: strings.SUCCESS_DELETE,
        type: 'ok',
      });

      await this.getList();
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: error.response?.data.errors.fields ?? strings.UNKNOWN_ERROR,
        type: 'fail',
      });
    } finally {
      this.context.commit('UPDATE_IS_LOADING', false);
    }
  }

  @Action({ rawError: true })
  async init(employeeId: string): Promise<void> {
    await this.context.commit('SET_EMPLOYEE_ID', employeeId);

    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    this.initList();
  }

  @Action({ rawError: true })
  async sort(params: { field: string; sort: string }): Promise<void> {
    const sortProcessed = await this.context.dispatch('sortProcessed', params);
    await this.context.commit('SET_SORT', sortProcessed);
    this.initListById(this.employeeId);
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string): Promise<void> {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', { moduleName: MODULE_NAME, amountItems: number });
    await this.initListById(this.employeeId);
  }
}

export default getModule(EmployeeClientRelationsModule);

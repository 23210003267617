import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import ResponseHandlerModule from '@/store/modules/responseHandler';

import { getEmployeeRelations } from '@/api/employees';
import { PageSort } from '@/lib/layouts/page/page.interface';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';

import { modalParams } from '@/store/employees/relationsModalParams';
import ModalsModule from '@/store/modals';
import EmployeesModule from '@/store/employees/index';
import { deleteEmployeeFromFavorite } from '@/api/shop';
import EmployeeRelationEntityModule from '@/store/employees/relationEntity';
import { UITable } from '@/lib/util/tableUtils';
import { strings } from '@/lib/stringConst';

const MODULE_NAME = 'employeeRelations';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
export class EmployeeShopRelationsModule extends PageBaseModule {
  modalParams: Record<string, Record<string, string | null | boolean | Record<string, string>>> = modalParams;
  employeeId = '';

  constructor(module: EmployeeShopRelationsModule) {
    super(module);

    const page = new PageEntity();
    page.values.actionPagination = 'employeeLogs/updatePage';
    this.pageSettings = page.values;
  }

  @Mutation
  GET_LIST(): void {
    super.getList();
  }

  @Mutation
  TOGGLE_MODAL(payload: { type: string; bool: boolean }) {
    this.modalParams[payload.type].isShow = payload.bool;
  }

  @Mutation
  SET_SORT(sorts: PageSort) {
    this.pageSettings.sort = sorts;
    window.localStorage.employeesRelationsSort = JSON.stringify(sorts);
  }

  @Mutation
  SET_EMPLOYEE_ID(id: string) {
    this.employeeId = id;
  }

  @Mutation
  SET_DELETE_ID(id: string) {
    this.modalParams.delete.id = id;
  }

  @Mutation
  SET_DELETE_EMPLOYEE_ID(id: string) {
    this.modalParams.delete.employeeId = id;
  }

  @Mutation
  SET_DELETE_TEXT(text: string) {
    this.modalParams.delete.text = text;
  }

  @Action({ rawError: true })
  async init(employeeId: string) {
    await this.context.commit('SET_EMPLOYEE_ID', employeeId);
    this.context.commit(
      'SET_SORT',
      window.localStorage.employeesRelationsSort ? JSON.parse(window.localStorage.employeesRelationsSort) : {}
    );

    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    this.initListById(employeeId);
  }

  @Action({ rawError: true })
  async initListById(employeeId: string) {
    const result = await this.requestByParams(employeeId);
    this.setRelations(result);
  }

  @Action({ rawError: true })
  async requestByParams(employeeId: string) {
    try {
      const sort = await this.getSortForRequest();
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);

      const result = await getEmployeeRelations(this.pageSettings.pageCurrent, itemsQuery, sort, { employeeId });

      return result.table;
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async setRelations(table: TableApiInterface) {
    const result = await this.prepareList(table);
    this.context.commit('SET_TABLE', result);
  }

  @Action({ rawError: true })
  prepareList(table: TableApiInterface) {
    const uiTable = new UITable(table as any);

    return uiTable
      .removeColumn('id')
      .removeColumn('clientMarketCode')
      .removeColumn('clientMarketId')

      .setProperty('_actions', 'align', 'right')

      .getTable() as any;
  }

  @Action({ rawError: true })
  async sort(params: { field: string; sort: string }) {
    const sortProcessed = await this.context.dispatch('sortProcessed', params);
    await this.context.commit('SET_SORT', sortProcessed);
    this.initListById(this.employeeId);
  }

  @Action({ rawError: true })
  deleteFromFavorite(params: { shopId: string; employeeId: string }) {
    this.context.commit('SET_DELETE_ID', params.shopId);
    this.context.commit('SET_DELETE_EMPLOYEE_ID', params.employeeId);
    this.context.commit(
      'SET_DELETE_TEXT',
      `Ты действительно хочешь удалить ${EmployeesModule.pageSettings.titleEdit} из любимого/нелюбимого?`
    );
    ModalsModule.updateParams(this.modalParams.delete);
    ModalsModule.openModalByType('warning');
  }

  @Action({ rawError: true })
  updateStatus(params: {
    shop: { id: string; name: string; actions: string[] };
    employeeId: string;
    id: string;
    comment: string;
  }) {
    this.context.commit('SET_DELETE_ID', params.shop.id.toString());
    this.context.commit('SET_DELETE_EMPLOYEE_ID', params.employeeId);

    EmployeeRelationEntityModule.editFavorite({
      id: params.id,
      comment: params.comment,
      shop: params.shop,
      employeeId: params.employeeId,
    });
  }

  @Action({ rawError: true })
  async deleteById(params: { id: string; employeeId: string }) {
    try {
      const result = await deleteEmployeeFromFavorite(params.id, this.modalParams.delete.employeeId as string);
      if (!result.message) {
        ResponseHandlerModule.showNotify({
          message: 'Сотрудник удалён из любимого/нелюбимого списка',
          type: 'ok',
        });
        this.initListById(this.modalParams.delete.employeeId as string);
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  saveUpdate() {
    EmployeeRelationEntityModule.saveUpdate({
      shopId: this.modalParams.delete.id as string,
      employeeId: this.modalParams.delete.employeeId as string,
    }).then(() => {
      this.hideModal('edit');
    });
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', { moduleName: MODULE_NAME, amountItems: number });
    await this.initListById(this.employeeId);
  }

  @Action({ rawError: true })
  showModal(type: string) {
    this.context.commit('TOGGLE_MODAL', { type: type, bool: true });
  }

  @Action({ rawError: true })
  hideModal(type: string) {
    EmployeeRelationEntityModule.resetModel();
    this.context.commit('TOGGLE_MODAL', { type: type, bool: false });
  }

  @Action({ rawError: true })
  setHatedWarning(shopId: string) {
    this.context.commit('SET_EMPLOYEE_ID', shopId);
    ModalsModule.updateParams(this.modalParams.hated);
    ModalsModule.openModalByType('warning');
  }
}

export default getModule(EmployeeShopRelationsModule);

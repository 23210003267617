
import { defineComponent } from 'vue';

import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TitleReturn from '@/components/TitleReturn.vue';
import AppBadgeCards from '@/components/badge/Cards.vue';
import StarIcon from '@/components/icons/Star.vue';

import { Cards, PreparedCard } from '@/store/employees/entity';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import EmployeeEntityModule from '@/store/employees/entity';
import EmployeesModule from '@/store/employees';

export default defineComponent({
  components: {
    TabsNav,
    TitleReturn,
    AppBadgeCards,
    StarIcon,
  },
  props: {
    currentTab: {
      type: String,
      required: true,
    },
  },
  computed: {
    employeeId(): string {
      return this.$route.params.employeeId as string;
    },
    isTest(): boolean {
      return EmployeeEntityModule.model.isTest;
    },
    rating(): string {
      return EmployeeEntityModule.model.rating;
    },
    cards(): boolean | Cards | PreparedCard {
      return EmployeeEntityModule.cards;
    },
    titlePage(): string {
      return EmployeesModule.pageSettings.titleEdit;
    },
    tabsNav(): TabsNavInterface {
      return EmployeeEntityModule.tabsNav;
    },
  },
  methods: {
    returnBefore(): void {
      this.$router.push({ name: 'employees' });
    },
  },
});

import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_title_tab = _resolveComponent("title-tab")!
  const _component_gui_button = _resolveComponent("gui-button")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_shops_tab = _resolveComponent("shops-tab")!
  const _component_clients_tab = _resolveComponent("clients-tab")!
  const _component_app_row = _resolveComponent("app-row")!

  return (_openBlock(), _createBlock(_component_app_row, {
    wrap: "nowrap",
    direction: "column",
    class: "cp-main-inner__wrapper"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_col, { grow: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_title_tab, _normalizeProps(_guardReactiveProps({ currentTab: _ctx.currentTab })), null, 16),
          (_ctx.canReadLaborRelations)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_gui_button, {
                  label: "Объекты",
                  color: _ctx.getColorButtonTab('shops'),
                  type: "button",
                  onBtnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeTab = 'shops'))
                }, null, 8, ["color"]),
                _createVNode(_component_gui_button, {
                  label: "Клиенты",
                  color: _ctx.getColorButtonTab('clients'),
                  type: "button",
                  onBtnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeTab = 'clients'))
                }, null, 8, ["color"])
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (_ctx.activeTab === 'shops')
        ? (_openBlock(), _createBlock(_component_shops_tab, { key: 0 }))
        : (_openBlock(), _createBlock(_component_clients_tab, { key: 1 }))
    ]),
    _: 1
  }))
}